import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import projects from "../data/projects";

const ProjectScreen = ({ match }) => {
  const project = projects.find((p) => {
    return parseInt(match.params.id) === p.id;
  });

  return (
    <>
      <Row className="mb-5">
        <Col>
          <Link to="/" className="redirect-back">
            <i className="fas fa-arrow-left"></i>
            <span className="go-back">Go back</span>
          </Link>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={8}>
          <h2 className="mb-3">{project.title}</h2>
          <div className="text-lg" style={{ color: "#333333" }}>
            {project.description}
          </div>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h5>Stack</h5>
          {project.stack.map((s, index) => (
            <div key={index} className="text-lg" style={{ color: "#333333" }}>
              {s}
            </div>
          ))}
        </Col>
        <Col>
          <h5>Type</h5>
          <div className="text-lg" style={{ color: "#333333" }}>
            {project.type}
          </div>
        </Col>
        <Col>
          <h5>Code</h5>
          <div className="text-lg" style={{ color: "#333333" }}>
            {project.repositoryUrl ? (
              <a
                href={project.repositoryUrl}
                target="_blank"
                rel="noreferrer"
                className="repository-link"
              >
                Repository
              </a>
            ) : (
              <div className="text-lg" style={{ color: "#333333" }}>
                Not available
              </div>
            )}
          </div>
        </Col>
        <Col>
          <h5>Live</h5>
          <div className="text-lg" style={{ color: "#333333" }}>
            {project.siteUrl ? (
              <a
                href={project.siteUrl}
                target="_blank"
                rel="noreferrer"
                className="live-link"
              >
                View site
              </a>
            ) : (
              <div className="text-lg" style={{ color: "#333333" }}>
                Not live
              </div>
            )}
          </div>
        </Col>
      </Row>

      <Image
        src={`${project.imageHeader}`}
        alt={project.title}
        fluid
        rounded
        className="my-3 shadow-lg mb-5"
      />

      <Row className="mb-5">
        <Col md={6}>
          <h2 className="mb-3">Goal of the project</h2>
          <div className="text-lg" style={{ color: "#333333" }}>
            {project.goalDescription}
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={6}></Col>
        <Col>
          <h2 className="mb-3">Stack explanation</h2>
          <div className="text-lg" style={{ color: "#333333" }}>
            {project.stackDescription}
          </div>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col md={6}>
          <Image
            src={`${project.bodyImage1}`}
            alt={project.title}
            fluid
            rounded
            className="my-3 shadow-lg"
          />
        </Col>
        <Col md={6}>
          <Image
            src={`${project.bodyImage2}`}
            alt={project.title}
            fluid
            rounded
            className="my-3 shadow-lg"
          />
        </Col>
        <Col md={6}>
          <Image
            src={`${project.bodyImage3}`}
            alt={project.title}
            fluid
            rounded
            className="my-3 shadow-lg"
          />
        </Col>
        <Col md={6}>
          <Image
            src={`${project.bodyImage4}`}
            alt={project.title}
            fluid
            rounded
            className="my-3 shadow-lg"
          />
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2 className="text-center mb-3">Problems faced</h2>
          <div
            className="text-lg text-center mb-3"
            style={{ color: "#333333" }}
          >
            {project.problemsDescription}
          </div>
          <Image
            src={`${project.problemsImage}`}
            alt={project.title}
            fluid
            rounded
            className="my-3 shadow-lg"
          />
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2 className="mb-3">What I have learnt</h2>
          <div className="text-lg" style={{ color: "#333333" }}>
            {project.learntDescription}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProjectScreen;
