import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      <Container>
        <h2>Let's keep in touch !</h2>
        <div className="text-lg mb-3" style={{ color: "#333333" }}>
          Reach me anytime, if you're looking for a developer too ! <br /> If
          you have any questions or ideas about a project.
        </div>
        <h6 className="mb-5">
          <a href="mailto:brithedeve@gmail.com" className="mailto">
            brithedeve@gmail.com
          </a>
        </h6>
      </Container>
    </footer>
  );
};

export default Footer;
