import React from "react";
import projects from "../data/projects";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const HomeScreen = () => {
  return (
    <>
      <Row className="mb-5">
        <Col>
          <h1>Hey, I'm Brian !</h1>
          <h1>
            Looking for{" "}
            <span style={{ color: "#007FFF" }}>jobs opportunities</span>,
          </h1>
          <h1>
            in <span style={{ color: "#FF0921" }}>web development</span>.
          </h1>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2 className="mb-3">About me</h2>
          <div className="text-lg" style={{ color: "#333333" }}>
            I'm 27 years old, I studied web development during a bootcamp called
            Wild Code School in <strong>Reims</strong> where I lived.
          </div>
          <div className="text-lg" style={{ color: "#333333" }}>
            I'm <strong>currently finishing</strong> a year-long work-study
            program with a company in <strong>Strasbourg</strong>.
          </div>
          <div className="text-lg" style={{ color: "#333333" }}>
            My skills were forged through those formations equipping me with
            both theoretical knowledge and hands-on industry experience.
          </div>
          <div className="text-lg" style={{ color: "#333333" }}>
            I'm looking to improve my skills in a friendly environment with
            experienced people.
          </div>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2 className="mb-3">Skills</h2>
          <div className="text-lg" style={{ color: "#333333" }}>
            I'm looking to improve my skills in a friendly environment with
            experienced people.
          </div>

          <div
            className="text-lg"
            style={{ color: "#333333", fontWeight: "600" }}
          >
            Docker, TypeScript, Git, GitHub, GitHub Actions, React, Node.js,
            Express, GraphQL, MySQL, PostgreSQL, Prisma, Redux, MongoDB, AWS...
          </div>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2 className="mb-3">Projects</h2>
          <Row>
            {projects.map((project) => (
              <Col md={6} key={project.id} className="mb-4">
                <div className="project-image-container">
                  <Image
                    src={`${project.imageHeader}`}
                    alt={project.title}
                    fluid
                    rounded
                    className="project-image"
                  />
                </div>
                <h3 className="mb-3">{project.title}</h3>
                <div className="text-lg mb-3" style={{ color: "#333333" }}>
                  {project.smallDescription}
                </div>

                <Link to={`/project/${project.id}`} className="view-project">
                  <h6 className="mb-5">view project</h6>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default HomeScreen;
