import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import ProjectScreen from './screens/ProjectScreen';
import HomeScreen from './screens/HomeScreen';
import ScrollToTop from './components/ScrollToTop';

function App() {
	return (
		<Router>
			<ScrollToTop />
			<Header />
			<main className="py-3">
				<Container>
					<Route path="/project/:id" component={ProjectScreen} />
					<Route path="/" component={HomeScreen} exact />
				</Container>
			</main>
			<Footer />
		</Router>
	);
}

export default App;
