import godriveHeader from "../images/6/godrive-1.png";
import godriveBodyImage1 from "../images/6/godrive-2.png";
import godriveBodyImage2 from "../images/6/godrive-3.png";
import godriveBodyImage3 from "../images/6/godrive-4.png";
import godriveBodyImage4 from "../images/6/godrive-5.png";
import godriveProblemsImage from "../images/6/godrive-6.png";

import watzeidHeader from "../images/5/watzeid-1.PNG";
import watzeidBodyImage1 from "../images/5/watzeid-2.PNG";
import watzeidBodyImage2 from "../images/5/watzeid-3.PNG";
import watzeidBodyImage3 from "../images/5/watzeid-4.PNG";
import watzeidBodyImage4 from "../images/5/watzeid-5.PNG";
import watzeidProblemsImage from "../images/5/watzeid-6.PNG";

import nextShopifyHeader from "../images/4/next-shopify-1.PNG";
import nextShopifyBodyImage1 from "../images/4/next-shopify-2.PNG";
import nextShopifyBodyImage2 from "../images/4/next-shopify-3.PNG";
import nextShopifyBodyImage3 from "../images/4/next-shopify-4.PNG";
import nextShopifyBodyImage4 from "../images/4/next-shopify-5.PNG";
import nextShopifyProblemsImage from "../images/4/next-shopify-6.PNG";

import tontonHeader from "../images/3/tonton-sommelier-1.jpg";
import tontonBodyImage1 from "../images/3/tonton-sommelier-2.PNG";
import tontonBodyImage2 from "../images/3/tonton-sommelier-3.jpg";
import tontonBodyImage3 from "../images/3/tonton-sommelier-4.PNG";
import tontonBodyImage4 from "../images/3/tonton-sommelier-5.PNG";
import tontonProblemsImage from "../images/3/tonton-sommelier-6.PNG";

import ecommerceHeader from "../images/2/mern-ecommerce-1.PNG";
import ecommerceBodyImage1 from "../images/2/mern-ecommerce-2.PNG";
import ecommerceBodyImage2 from "../images/2/mern-ecommerce-3.PNG";
import ecommerceBodyImage3 from "../images/2/mern-ecommerce-4.PNG";
import ecommerceBodyImage4 from "../images/2/mern-ecommerce-5.PNG";
import ecommerceProblemsImage from "../images/2/mern-ecommerce-6.PNG";

import magHeader from "../images/1/mern-mag-1.png";
import magBodyImage1 from "../images/1/mern-mag-2.png";
import magBodyImage2 from "../images/1/mern-mag-3.PNG";
import magBodyImage3 from "../images/1/mern-mag-4.PNG";
import magBodyImage4 from "../images/1/mern-mag-5.PNG";
import magProblemsImage from "../images/1/mern-mag-6.PNG";

const projects = [
  {
    id: 1,
    title: "Godrive",
    smallDescription: "A school project like BlaBlaCar.",
    description:
      "Platform that connects drivers with empty seats to passengers looking for a ride. Allows people to share long-distance journeys, reducing travel costs and environmental impact.",
    stack: [
      "PostgreSQL",
      "Express",
      "GraphQL",
      "Next",
      "TypeScript",
      "-",
      "Docker",
      "GitHub Actions",
      "CI/CD",
      "-",
      "TypeORM",
      "Apollo Client / Server",
      "...",
    ],
    type: "School project",
    repositoryUrl: "",
    siteUrl: "https://staging-godrive.brian.kitchen/",
    imageHeader: godriveHeader,
    goalDescription:
      "Project aimed to create a BlaBlaCar like but very simple, adhering to specific requirements provided at the beginning.",
    stackDescription:
      "The stack was imposed by the school, ensuring a standardized development environment for all students.",
    bodyImage1: godriveBodyImage1,
    bodyImage2: godriveBodyImage2,
    bodyImage3: godriveBodyImage3,
    bodyImage4: godriveBodyImage4,
    problemsDescription:
      "The project presented challenges in implementing CI/CD pipelines and managing multiple environments, requiring careful configuration and deployment strategies.",
    problemsImage: godriveProblemsImage,
    learntDescription:
      "Through this project, I gained valuable experience with CI/CD practices, Docker containerization, TypeScript, Next.js, and improved my skills in various new technologies...",
  },
  {
    id: 2,
    title: "Watzeid",
    smallDescription: 'A project to build a social network like  "the others."',
    description:
      "It's a social network like Instagram, where you can upload photos, like posts, follow people, comment, manage your profile and a lot more...",
    stack: [
      "PostgreSQL",
      "Express",
      "React",
      "Node",
      "-",
      "Prisma",
      "React Query",
      "...",
    ],
    type: "Personal project",
    repositoryUrl: "https://github.com/brithe00/watzeid",
    siteUrl: "",
    imageHeader: watzeidHeader,
    goalDescription:
      "The main goal was to build a 'huge' project like as I like to say, I totally built it by myself I didn't watched any courses, I chose the stack myself, I wanted to work with Prisma and React Query, I've never used it.",
    stackDescription:
      "Use PostgreSQL with Prisma, and React Query to try them, really impressive packages, I've never used them and I recommend them.",
    bodyImage1: watzeidBodyImage1,
    bodyImage2: watzeidBodyImage2,
    bodyImage3: watzeidBodyImage3,
    bodyImage4: watzeidBodyImage4,
    problemsDescription:
      "How to use React Query and Prisma but after you scroll throught the documentation you realize how easy is it to use.",
    problemsImage: watzeidProblemsImage,
    learntDescription:
      "How to work with React Query, see how mutations and queries works, how you can get your data from your cache and use them in any components. React Query is very impressive and gives you a lot of help. Also use an ORM and I think Prisma is the perfect one, I learnt how to interact with my database, even with relationships tables.",
  },
  {
    id: 3,
    title: "Modern eCommerce - Next.js, Shopify",
    smallDescription: "An ecommerce project using recent technologies.",
    description:
      "Very minimalist shop, can be improved ! I always wanted to know how to create an ecommerce experience using Shopify, without learning the Shopify templating language, but instead using my knowledge.",
    stack: ["Next.js", "Shopify Storefront API", "TailwindCSS", "GraphQL"],
    type: "Personal project",
    repositoryUrl: "",
    siteUrl: "",
    imageHeader: nextShopifyHeader,
    goalDescription:
      "The main goal was to use the Shopify Storefront API to crate an ecommerce experience.",
    stackDescription:
      "Next.js, Shopify API, TailwindCSS, GraphQL to use recent technologies and improve my skills.",
    bodyImage1: nextShopifyBodyImage1,
    bodyImage2: nextShopifyBodyImage2,
    bodyImage3: nextShopifyBodyImage3,
    bodyImage4: nextShopifyBodyImage4,
    problemsDescription:
      "No problems faced, the Shopify API has a lot of information available, you need to take your time and see what you can use.",
    problemsImage: nextShopifyProblemsImage,
    learntDescription:
      "How work the Shopify Storefront API, this shop is minimalist, you can add much more functionalities !",
  },
  {
    id: 4,
    title: "MERN Mag",
    smallDescription:
      "An application where you can post articles. It's a magazine, blog like.",
    description:
      "After my last project, MERN Ecommerce, I wanted to keep practicing  what I have learnt. I wanted to create a magazine, blog like where you have to use a rich text editor without using a CMS. It's a CRUD application with a back office.",
    stack: ["MongoDB", "Express", "React", "Node.js", "Redux"],
    type: "Personal project",
    repositoryUrl: "https://github.com/brithe00/mern-mag",
    siteUrl: "",
    imageHeader: magHeader,
    goalDescription:
      "The main goal was to use a rich text editor, to know how it works, without using a CMS.",
    stackDescription:
      "MongoDB, Express, React, Node.js to keep working with this stack, to improve myself.",
    bodyImage1: magBodyImage1,
    bodyImage2: magBodyImage2,
    bodyImage3: magBodyImage3,
    bodyImage4: magBodyImage4,
    problemsDescription:
      "I had problems with the main objective, the text editor. Store in database, and fetch it in the front-end. I had to do some research to know how it's working. I have used draft.js to convert the content and re-use it.",
    problemsImage: magProblemsImage,
    learntDescription:
      "How draft.js represents rich text data that has all sorts of styles, blocks, entities...",
  },
  {
    id: 5,
    title: "MERN Ecommerce",
    smallDescription:
      "An e-commerce platform from scratch using the MERN stack.",
    description:
      "An e-commerce platform with a lot of features. User authentication with profile,  shopping cart management, checkout process, back-office, product reviews...",
    stack: ["MongoDB", "Express", "React", "Node.js", "Redux"],
    type: "Personal project",
    repositoryUrl: "https://github.com/brithe00/mern-ecommerce",
    siteUrl: "",
    imageHeader: ecommerceHeader,
    goalDescription:
      "I wanted to create a big project. This is why I think e-commerce project is good because it covers a lot of features to learn.",
    stackDescription:
      "MongoDB, Express, React, Node.js and Redux for the state management. I have studied Express, React and Node.js and wanted to improve my skills. I added MongoDB to learn new things.",
    bodyImage1: ecommerceBodyImage1,
    bodyImage2: ecommerceBodyImage2,
    bodyImage3: ecommerceBodyImage3,
    bodyImage4: ecommerceBodyImage4,
    problemsDescription:
      "I followed a course to build this website a lot of problems were solved easily.",
    problemsImage: ecommerceProblemsImage,
    learntDescription:
      "Redux, I wasn't good with Redux now I think I can create a lot of things with it. I also learnt to create the differents features of an e-commerce website.",
  },
  {
    id: 6,
    title: "Tonton Sommelier",
    smallDescription:
      "A web application to learn about oenology while playing.",
    description:
      "Tonton Sommelier is a web application to learn about oenology, wine, while you play a game. The game is a quiz, between questions there are guides to learn how to taste it, look it, smell it. You can replay the game if you want to and you can manage your profile.",
    stack: ["React", "AWS", "Serverless"],
    type: "Internship",
    repositoryUrl: "",
    siteUrl: "https://tontonsommelier-app.com/login",
    imageHeader: tontonHeader,
    goalDescription:
      "The goal was to create their web application from zero to production during the intership.",
    stackDescription:
      "React, AWS Amplify, DynamoDB, AppSync, Lambda... We discovered the universe of Amazon Web Services with the Amplify framework and the others services.",
    bodyImage1: tontonBodyImage1,
    bodyImage2: tontonBodyImage2,
    bodyImage3: tontonBodyImage3,
    bodyImage4: tontonBodyImage4,
    problemsDescription:
      "It was a new technology, we first had some problems when you learn something new but we managed to resolve it !",
    problemsImage: tontonProblemsImage,
    learntDescription:
      "Like I said before we totally discovered this universe. AWS is so powerful and I'm very happy to have discovered this innovative and very cool stack. I will use AWS in the future with all the opportunities that this offers us. Furthermore this represents the serverless and follow the trend.",
  },
];

export default projects;
