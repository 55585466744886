import React from 'react';
import { Container } from 'react-bootstrap';

const Header = () => {
	return (
		<header className="mb-4">
			<Container>
				<h3>brithe.</h3>
				<div className="header-separator"></div>
				<div className="mt-3">
					<a
						href="https://www.github.com/brithe00"
						target="_blank"
						rel="noreferrer"
						className="redirect-links"
					>
						<i className="fab fa-github mr-4"></i>
					</a>

					<a
						href="https://www.linkedin.com/in/brian-thellier"
						target="_blank"
						rel="noreferrer"
						className="redirect-links"
					>
						<i className="fab fa-linkedin-in"></i>
					</a>
				</div>
			</Container>
		</header>
	);
};

export default Header;
